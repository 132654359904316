import React from 'react';
import '../css/copyright.css';

const Copyright = () => {
    return (
        <div data-testid="copyright-component">
            <p className='info'>ⓘ<span> Disponible</span> pour travailler en freelance</p>
            <p className='copyright'>©2023 Maxime Vantorre</p>
        </div>
    );
};

export default Copyright;