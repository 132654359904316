import React, { useEffect, useState, useMemo } from 'react';
import '../css/slider.css';

// Importez vos pages ici
import Page_1 from '../pages/Page_1';
import Page_2 from '../pages/Page_2';
import Page_3 from '../pages/Page_3';
import Page_4 from '../pages/Page_4';

const Slider = () => {
    const [startY, setStartY] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const handleScroll = (event) => {
            const scrolledPixels = event.deltaY * 2;
            const scrollThreshold = 100; // Modifier le seuil de défilement ici
            /*console.log('Pixels scrolled:', scrolledPixels);*/

            if (Math.abs(scrolledPixels) >= scrollThreshold) {
                if (scrolledPixels > 0 && currentPage < 3) {
                    setCurrentPage(currentPage + 1);
                } else if (scrolledPixels < 0 && currentPage > 0) {
                    setCurrentPage(currentPage - 1);
                }
            }
            // Si la quantité de défilement est inférieure au seuil, aucune action n'est effectuée
        };

        const handleTouchStart = (event) => {
            setStartY(event.touches[0].clientY);
        };

        const handleTouchEnd = (event) => {
            const deltaY = event.changedTouches[0].clientY - startY;
            const swipeThreshold = 60; // Modifier la valeur du seuil de swipe ici

            if (Math.abs(deltaY) >= swipeThreshold) {
                if (deltaY > 0 && currentPage > 0) {
                    setCurrentPage(currentPage - 1);
                } else if (deltaY < 0 && currentPage < 3) {
                    setCurrentPage(currentPage + 1);
                }
            }
            // Si la distance de swipe est inférieure au seuil, aucune action n'est effectuée
        };
        window.addEventListener('wheel', handleScroll);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [startY, currentPage]);

    const pages = useMemo(() => [Page_1, Page_2, Page_3, Page_4], []);

    useEffect(() => {
        pages.forEach((_, index) => {
            const pageElement = document.querySelector(`.page${index + 1}`);
            if (pageElement) {
                pageElement.style.transform = `translate3d(0, ${100 * (index - currentPage)}vh, 0)`;
            }
        });
    }, [currentPage, pages]);

    const renderPageIndicator = () => {
        return (
            <div className="page-indicator">
                {[0, 1, 2, 3].map((pageIndex) => (
                    <div
                        key={pageIndex}
                        className={`dot ${currentPage === pageIndex ? 'active' : 'inactive'}`}
                    ></div>
                ))}
            </div>
        );
    };

    return (
        <div className="slider-container" data-testid="slider-component">
            {renderPageIndicator()}
            {pages.map((Page, index) => (
                <div
                    key={index}
                    className={`slider-div page${index + 1}`} // Ajoutez une classe de page pour le style
                >
                    <Page />
                </div>
            ))}
        </div>
    );
};

export default Slider;