import React from 'react';
import BtnSociaux from '../components/BtnSociaux';
import ContactForm from '../components/ContactForm';
import '../css/pages.css';

const Page_4 = () => {
    return (
        <div className='b-4'>
            <h2 className='t-name'>Contact</h2>
            <div className='b-cont'>
                <p>N'hésitez pas à me contacter via mes réseaux sociaux.</p>
                <BtnSociaux />
                <ContactForm />
            </div>
        </div>
    );
};

export default Page_4;