import React from 'react';
import Slider from './components/Slider';
import Copyright from './components/Copyright';

const App = () => {
  return (
    <div className='App'>
      <Slider />
      <Copyright />
    </div>
  );
};

export default App;