import React from 'react';
import '../css/pages.css';

const Page_3 = () => {
    return (
        <div className='b-3'>
            <h2 className='t-name'>Projets</h2>
            <div className='b-pro-all'>
                <div className='b-pro'>
                    <div className='b-left'>
                        <h2>A chiper a choper</h2>
                        <p>Conception d'une carte interactive des boissons (Site web dynamique).<br />
                            - Mise à jour automatique des prix en fonction des horaires.<br />
                            - Personnalisation de la sélection des boissons selon les plages horaires.</p>
                    </div>
                    <div className='b-right'>
                        <div className='img-ref'></div>
                    </div>
                    <div className='b-bottom'>
                        <p>HTML - CSS</p>
                        <p>Javascript</p>
                        <p>Json</p>
                    </div>
                </div>
                <div className='b-pro'>
                    <div className='b-left'>
                        <h2>COPILOTE</h2>
                        <p>Ce logiciel offre aux bars la possibilité de modifier instantanément les informations de leurs cartes de boissons via une interface. <br /> Compatible avec les systèmes d'exploitation Mac et Windows.</p>
                    </div>
                    <div className='b-right'>
                        <div className='img-ref-copi'></div>
                    </div>
                    <div className='b-bottom'>
                        <p>Electron</p>
                        <p>Javascript</p>
                        <p>Json</p>
                    </div>
                </div>
                <div className='b-pro'>
                    <div className='b-left'>
                        <h2>AR mural</h2>
                        <p>Street art intéractif coréalisé avec l'artiste Allem Bilel.<br />
                            L'objectif ici était de permettre aux passants de voir la fresque s'animer à l'aide de leur smartphone.</p>
                    </div>
                    <div className='b-right'>
                        <div className='img-ref-ar'></div>
                    </div>
                    <div className='b-bottom'>
                        <p>Unity</p>
                        <p>Vulforia</p>
                        <p>C#</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Page_3;