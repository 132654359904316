import React from 'react';
import '../css/btnSociaux.css';
import { ReactComponent as LinkedinIcon } from '../assets/Img/Linkedin.svg';
import { ReactComponent as MaltIcon } from '../assets/Img/Malt.svg';
import { ReactComponent as MailIcon } from '../assets/Img/Mail.svg';

const BtnSociaux = () => {
    return (
        <div className='b-sociaux'>
            <a className='a-btn' href="https://www.linkedin.com/in/maxime-vantorre-049aa425b" target="_blank" rel="noreferrer">
                <button className='btn-contact'><LinkedinIcon className="icone-svg-btn" />LinkedIn</button>
            </a>

            <a className='a-btn' href="https://www.malt.fr/profile/maximevantorre" target="_blank" rel="noreferrer">
                <button className='btn-contact'><MaltIcon className="icone-svg-btn" />Malt</button>
            </a>

            <a className='a-btn' href="mailto:maxime.vantorre@hotmail.fr">
                <button className='btn-contact'><MailIcon className="icone-svg-btn" />maxime.vantorre@hotmail.fr</button>
            </a>
        </div>
    );
};

export default BtnSociaux;