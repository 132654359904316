import React, { useState } from 'react';
import '../css/contactForm.css';
import emailjs from 'emailjs-com';

// Configuration de votre service EmailJS
const SERVICE_ID = 'service_a1chmcp'; // Remplacez par votre ID de service
const TEMPLATE_ID = 'template_djzjdcp'; // Remplacez par votre ID de modèle
const USER_ID = 'Dfihxk_U6VeBNcyf_'; // Remplacez par votre ID d'utilisateur

emailjs.init(USER_ID);

const ContactForm = () => {
    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        message: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        const formDataToSend = {
            nom: formData.nom,
            email: formData.email,
            message: formData.message,
            from_name: formData.nom, // Ajoutez le nom de l'expéditeur
            from_email: formData.email // Ajoutez l'e-mail de l'expéditeur
        };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, formDataToSend)
            .then((response) => {
                console.log('Email envoyé avec succès!', response);
                // Afficher le message de succès à l'utilisateur
                setSuccessMessage('Votre message a été envoyé avec succès!');
                // Effacer les messages d'erreur précédents
                setErrorMessage('');
                // Réinitialiser le formulaire après l'envoi réussi
                setFormData({
                    nom: '',
                    email: '',
                    message: ''
                });

                setTimeout(() => {
                    setSuccessMessage('');
                }, 5000);

            })
            .catch((error) => {
                console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
                // Afficher le message d'erreur à l'utilisateur
                setErrorMessage('Erreur lors de l\'envoi de l\'e-mail. Veuillez réessayer.');
                // Effacer les messages de succès précédents
                setSuccessMessage('');

                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
            });
    };

    return (
        <form className="formulaire" onSubmit={handleSubmit}>
            <div>
                <input
                    type="text"
                    className="text-input"
                    id="nom"
                    name="nom"
                    placeholder="Nom.."
                    value={formData.nom}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div>
                <input
                    type="email"
                    className="text-input"
                    id="email"
                    name="email"
                    placeholder="Email.."
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
            </div>
            <div>
                <textarea
                    id="message"
                    className="text-area"
                    name="message"
                    rows="4"
                    placeholder="Votre message.."
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                ></textarea>
            </div>
            <div>
                {successMessage && (
                    <p className='success'>{successMessage}</p>
                )}
                {errorMessage && (
                    <p className='error'>{errorMessage}</p>
                )}
            </div>
            <div>
                <button className="btn-submit" type="submit">
                    Envoyer
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
