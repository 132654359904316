import React from 'react';
import '../css/pages.css';
import { ReactComponent as PhotoshopIcon } from '../assets/Img/Ps.svg';
import { ReactComponent as IllustatorIcon } from '../assets/Img/Ai.svg';
import { ReactComponent as FigmaIcon } from '../assets/Img/Figma.svg';
import { ReactComponent as SubstanceIcon } from '../assets/Img/Pt.svg';
import { ReactComponent as UnityIcon } from '../assets/Img/Unity.svg';
import { ReactComponent as CinemaIcon } from '../assets/Img/Cinema.svg';
import { ReactComponent as PythonIcon } from '../assets/Img/Python.svg';
import { ReactComponent as CIcon } from '../assets/Img/C.svg';
import { ReactComponent as HTMLIcon } from '../assets/Img/HTML.svg';
import { ReactComponent as ReactIcon } from '../assets/Img/React.svg';
import { ReactComponent as ThreeIcon } from '../assets/Img/Three.svg';
import { ReactComponent as ElectronIcon } from '../assets/Img/Electron.svg';

const Page_2 = () => {
    return (
        <div className='b-2'>
            <h2 className='t-name'>Compétences</h2>
            <div className='b-com-all'>
                <div className='b-com'>
                    <h3>Design</h3>
                    <blockquote>
                        <span>
                            <FigmaIcon className="icone-svg" />
                            <p className=''>Figma</p>
                        </span>
                        <span>
                            <IllustatorIcon className="icone-svg" />
                            <p className=''>Illustrator</p>
                        </span>
                        <span>
                            <PhotoshopIcon className="icone-svg" />
                            <p className=''>Photoshop</p>
                        </span>
                    </blockquote>
                </div>
                <div className='b-com'>
                    <h3>Front-end</h3>
                    <blockquote>
                        <span>
                            <ReactIcon className="icone-svg" />
                            <p className=''>React</p>
                        </span>
                        <span>
                            <ThreeIcon className="icone-svg" />
                            <p className=''>Three.js</p>
                        </span>
                        <span>
                            <HTMLIcon className="icone-svg" />
                            <p className=''>HTML - CSS - Javascript</p>
                        </span>
                    </blockquote>
                </div>
                <div className='b-com'>
                    <h3>Back-end</h3>
                    <blockquote>
                        <span>
                            <ElectronIcon className="icone-svg" />
                            <p className=''>Electron</p>
                        </span>
                        <span>
                            <CIcon className="icone-svg" />
                            <p className=''>C#</p>
                        </span>
                        <span>
                            <PythonIcon className="icone-svg" />
                            <p className=''>Python</p>
                        </span>
                    </blockquote>
                </div>
                <div className='b-com'>
                    <h3>Autres</h3>
                    <blockquote>
                        <span>
                            <UnityIcon className="icone-svg" />
                            <p className=''>Unity</p>
                        </span>
                        <span>
                            <CinemaIcon className="icone-svg" />
                            <p className=''>Cinema 4D</p>
                        </span>
                        <span>
                            <SubstanceIcon className="icone-svg" />
                            <p className=''>Substance Painter</p>
                        </span>
                    </blockquote>
                </div>
            </div>
        </div >
    );
};

export default Page_2;