import React from 'react';
import BtnSociaux from '../components/BtnSociaux';
import '../css/pages.css';

const Page_1 = () => {
    return (
        <div className='b-1'>
            <h3 className='t-welcome'>Bienvenue ! Je suis</h3>
            <h2 className='t-name'>Maxime Vantorre</h2>
            <blockquote>
                <p className='t-presentation'>Développeur polyvalent, passionné par l’art de donner vie aux idées numériques.<br />
                    Je fusionne diverses technologies pour créer des solutions innovantes.
                </p>
            </blockquote>
            <BtnSociaux />
        </div >
    );
};

export default Page_1;